body {
  font-family: 'Source Sans Pro', sans-serif;
}

body.dark {
  color: white;
  background-color: #6b6b6b;
}

a {
  color: blue;
  text-decoration: none;
}

a:hover {
  color: cornflowerblue;
  text-decoration: none;
}

a:active {
  color: cornflowerblue;
  text-decoration: none;
}

.no-margin {
  margin: 0;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.heading-location-access {
  position: fixed;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-item {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card-body {
  transition: all 0.2s;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.1);
  /* background-color: #fff; */
  border-radius: 5px;
  width: 400px;
  height: 150px;
  margin: 10px;
  position: relative;
}

body.dark .card-body {
  transition: all 0.2s;
  box-shadow: 0 2rem 6rem rgba(255, 255, 255, 0.14);
  border-radius: 5px;
  width: 400px;
  height: 150px;
  margin: 10px;
  position: relative;
}

.card-body:hover {
  background-color: #efefef;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

body.dark .card-body:hover {
  background-color: #6d6d6d;
  box-shadow: 0 2rem 6rem rgba(255, 255, 255, 0.22);
  transform: translateY(-2px);
}

.card-time {
  position: absolute;
  margin: 0;
  padding: 10px 0 0 10px;
}

.card-date {
  left: 0;
  bottom: 0;
  padding: 0 0 10px 10px;
  position: absolute;
}

div.card-degree {
  bottom: 0;
  padding: 15px;
  position: absolute;
  right: 0;
}

.card-degree {
  font-size: 1.9rem;
  font-weight: bold;
}

.card-min-degree {
  color: gray;
  font-size: 1.3rem;
}

body.dark .card-min-degree {
  color: #404040;
  font-size: 1.3rem;
}

.card-separator {
  font-size: 1.65rem;
  color: gray;
}

.card-weather-emoji {
  font-size: 3rem;
  margin-top: 20px;
  display: inherit;
}

.header {
  background-color: #f1f1f1;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.progress-container {
  width: 100%;
  height: 4px;
  background: #ccc;
}

body.dark .progress-container {
  width: 100%;
  height: 4px;
  background: #bfbfbf;
}

.progress-bar {
  height: 4px;
  background: #666666;
  width: 0%;
}

body.dark .progress-bar {
  height: 4px;
  background: #ffffff;
  width: 0%;
}

.today-weather {
  border: white;
  height: 200px;
  transform: translate(-50%, 0);
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.1);
  width: 50vw;
  position: relative;
  left: 50%;
}

body.dark .today-weather {
  border: white;
  height: 200px;
  transform: translate(-50%, 0);
  box-shadow: 0 2rem 6rem rgba(255, 255, 255, 0.14);
  width: 50vw;
  position: relative;
  left: 50%;
}

@media only screen and (max-width: 700px) {
  .today-weather {
    width: 100vw;
  }
}

.today-weather:hover {
  background-color: #efefef;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -2px);
}

body.dark .today-weather:hover {
  background-color: #6d6d6d;
  box-shadow: 0 2rem 6rem rgba(255, 255, 255, 0.22);
  transform: translate(-50%, -2px);
}

.today-degree {
  font-size: 2.3rem;
  font-weight: bold;
  display: block;
  text-align: center;
}

.today-city {
  border-bottom: 4px solid #80808063;
  padding: 0 5px;
}

body.dark .today-city {
  border-bottom: 4px solid #ffffff;
  padding: 0 5px;
}

.today-sticky-container>span>span {
  padding: 0 5px 0 0;
}

.today-sticky-container {
  position: fixed;
  top: 4px;
  width: 100%;
  height: 58px;
  z-index: 1;
  background: white;
}

body.dark .today-sticky-container {
  position: fixed;
  top: 4px;
  width: 100%;
  height: 58px;
  z-index: 1;
  background: #6f6f6f;
}

.today-sticky-temperature {
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px 20px;
  font-weight: bold;
}

.today-as-emoji {
  font-size: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.today-sunrise {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.today-sunset {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.today-min-max-degree {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px;
}

.btn-fetch {
  transition: opacity 0.5s ease-out;
  position: relative;
  left: 50%;
  opacity: 1;
  transform: translate(-50%, 0);
  background-color: white;
  border: 5px solid #efefef;
  outline: none;
  padding: 13px 50px;
  min-width: 50%;
  font-weight: 800;
  font-stretch: extra-expanded;
  font-size: medium;
  margin: 10px 0 10px 0;
  border-radius: 6px;
}

.btn-fetch:hover {
  background-color: #efefef;
  border: 5px solid #efefef;
}

.switch {
  -webkit-appearance: none;
  height: 1rem;
  width: 3rem;
  background-color: #a0a0a0;
  border-radius: 43px;
  cursor: pointer;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  margin: 15px 20px;
}

body.dark .switch {
  -webkit-appearance: none;
  height: 1rem;
  width: 3rem;
  background-color: #ffffff;
  border-radius: 43px;
  cursor: pointer;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  margin: 15px 20px;
}

.switch::after {
  top: 1px;
  left: 2px;
  content: '';
  width: 0.8rem;
  height: 0.8rem;
  background-color: #fff;
  position: absolute;
  border-radius: 100%;
  transition: 0.5s;
}

body.dark .switch::after {
  top: 1px;
  left: 2px;
  content: '';
  width: 0.8rem;
  height: 0.8rem;
  background-color: #6b6b6b;
  position: absolute;
  border-radius: 100%;
  transition: 0.5s;
}

.switch:checked {
  background-color: #6b6b6b;
}

.switch:checked::after {
  transform: translateX(2rem);
}

.switch:focus {
  outline-color: transparent;
}